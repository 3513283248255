<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <FormCardTitle
          v-model="form"
          :title="`${isUpdate ? 'Editar' : 'Cadastrar'} Unidade`"
        />

        <!-- Fields -->
        <FormCardItem title="Informações">
          <CCol sm="6">
            <CInput
              id="first"
              v-model="form.title"
              label="Título*"
              placeholder="Título"
              required
              :disabled="processing"
            />
          </CCol>
          <CCol sm="6">
            <FriendlyUrl
              :value="form.slug"
              :based-on="form.title"
              :entity="$route.params.id"
              field="slug"
              model="unities"
              @change="(value) => (form.slug = value)"
            />
          </CCol>

          <CCol lg="12">
            <FileUploader
              id="imageUpload"
              :max-files="1"
              :uploader-info="imageInfo"
              extensions="jpg,jpeg,png"
              accept-types="image/png,image/jpeg"
              :enable-multiple="false"
              :medias="medias"
              :min-cropped-width="604"
              :min-cropped-height="445"
              :enable-edit-on-api-table="false"
              :sizeFile="1024 * 1024 * 5"
            />
          </CCol>
        </FormCardItem>
        <FormCardItem title="Contato">
          <CCol sm="6">
            <CInput
              v-model="form.localization"
              label="Localização*"
              placeholder="Digite o endereço"
              description="Ex: R. Augusto Soares, 550 - Parque Industrial, Ribeirão Preto - SP"
              required
              :disabled="processing"
            />
          </CCol>
          <CCol sm="6">
            <CInput
              v-model="form.map"
              label="Mapa*"
              placeholder="Insira o iframe do Google Maps"
              description='Utilizar iframe do Google Maps Ex: <iframe src=""></iframe>'
              required
              :disabled="processing"
            />
          </CCol>
          <CCol sm="6">
            <CInput
              v-model="form.responsible_for_contacts"
              label="E-mail(s) para recebimento de formulário*"
              placeholder="email@mail.com"
              description="Possibilidade de mais de um e-mail separado por ;"
              required
              :disabled="processing"
              :isValid="isValidResponsibleEmails"
              invalidFeedback="Por favor, insira e-mails válidos"
            />
          </CCol>
          <CCol sm="6">
            <CInput
              v-model="form.email"
              label="E-mail de contato"
              placeholder="email@mail.com"
              description="Apenas para exibição nas unidades"
              :disabled="processing"
              :isValid="isValidEmail"
              invalidFeedback="Por favor, insira um e-mail válido"
            />
          </CCol>
          <CCol sm="6">
            <div role="group" class="form-group">
              <label for="phone"> Telefone </label>
              <input
                id="phone"
                type="text"
                class="form-control"
                v-mask="phoneMask"
                v-model="form.phone"
                label="Telefone"
                :disabled="processing"
                placeholder="(99) 99999-9999"
              />
            </div>
          </CCol>
          <CCol sm="6">
            <div role="group" class="form-group">
              <label for="whatsapp"> Whatsapp </label>
              <input
                id="whatsapp"
                type="text"
                class="form-control"
                v-mask="whatsappMask"
                v-model="form.whatsapp"
                label="Whatsapp"
                placeholder="(99) 99999-9999"
                :disabled="processing"
              />
            </div>
          </CCol>
        </FormCardItem>

        <CRow v-if="isUpdate">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="isUpdate"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
          >
            Limpar Alterações
          </a>
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import Service from '@/services/unity.service'
import CardLog from '@/components/ui/CardLog'
import FileUploader from '@/components/ui/Fileuploader'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import FormCardTitle from '@/components/ui/Form/FormCardTitle'
import FriendlyUrl from '@/components/ui/FriendlyUrl'

import moment from 'moment'
import Modal from '@/components/ui/Modal'
import { cloneDeep } from 'lodash'
import { isValidEmail, phoneMask } from '@/utils'

const defaultItem = () => ({
  email: '',
  localization: '',
  main_image_id: '',
  map: '',
  phone: '',
  responsible_for_contacts: '',
  status: true,
  title: '',
  whatsapp: '',
  slug: ''
})

export default {
  metaInfo: {
    title: 'Unidades',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: {
    CardLog,
    FileUploader,
    Modal,
    FormCardItem,
    FormCardTitle,
    FriendlyUrl
  },

  data: () => ({
    item: defaultItem(),
    form: defaultItem(),
    medias: [],
    log: {},
    isLoading: true,
    processing: false,
    redirect: true,
    modal: {
      show: false,
      title: '',
      message: '',
      color: ''
    },
    imageInfo: {
      label: 'Imagem'
    }
  }),

  async created() {
    this.setItem()
  },

  computed: {
    phoneMask() {
      return phoneMask(this.form.phone || '')
    },
    whatsappMask() {
      return phoneMask(this.form.whatsapp || '')
    },
    isUpdate() {
      return !!this.$route.params.id
    },
    isValidResponsibleEmails() {
      const emails = this.form.responsible_for_contacts
      if (emails === this.item.responsible_for_contacts || !emails) return null
      return emails
        .split(';')
        .reduce((prev, next) => prev && (!next || isValidEmail(next)), true)
    },
    isValidEmail() {
      if (this.form.email === this.item.email) return null
      return isValidEmail(this.form.email)
    }
  },

  methods: {
    async setItem() {
      const { id } = this.$route.params
      if (id) {
        const data = await Service.show(id)

        if (data) {
          this.item = { ...data }
          this.medias = [...this.item.main_image]

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.form = cloneDeep(this.item)
    },

    resetForm() {
      this.medias = []
      this.form = defaultItem()
      this.item = defaultItem()
      this.setItem()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      if (!this.medias.length) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Por favor preencha a imagem',
          color: 'danger'
        }
        return
      }

      if (this.isValidResponsibleEmails === false || isValidEmail === false) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Por favor insira emails válidos',
          color: 'danger'
        }

        return false
      }

      this.processing = true

      let response
      let message

      const { id } = this.$route.params
      const [mainImage] = this.medias
      const data = { ...this.form, id, main_image_id: mainImage.id }

      if (this.isUpdate) {
        response = await Service.update(id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  }
}
</script>
